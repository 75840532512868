import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';
import { alertActions } from './redux/actions';

import SAHeader from "./views/header/SAHeader";
import Footer from "./views/footer/Footer";
import TopAlert from './views/components/top.alert';
import Loader from './views/components/loader.spinner';
import BlockUi from 'react-block-ui';
import { jwt } from './tools';
import './styles/block-ui.scss';
import AdminHeader from "./views/header/AdminHeader";

// Pages
const LegalNotice = React.lazy(() => import('./views/containers/legalnotice'));
const Page404 = React.lazy(() => import('./views/containers/404'));
const ErrorPage = React.lazy(() => import('./views/containers/500'));
const Login = React.lazy(() => import('./views/containers/login'));
const Home = React.lazy(() => import('./views/containers/signatureForm'));

const AdminContent = React.lazy(() => import('./views/layout/AdminContent'));

class App extends Component {

  render() {
    const { loading, alert } = this.props;
    const isTokenExpired = jwt.isExpired();

    return (
      <div className="App">
        <HashRouter>
          <React.Suspense fallback={<Loader/>}>
            {loading.pending &&
              <Loader message={loading.message}/>
            }
            <BlockUi tag="div" className="app-content" blocking={loading.pending}>
              {alert.message && alert.type == 'error-bo' &&
                <TopAlert/>
              }
              {alert.message && alert.type == 'error-fo' &&
                <Redirect to="/error" />
              }
              <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> 
                  {isTokenExpired &&
                    <Redirect to="/login" />
                  }

                  <Route exact path="/error" name="Error page" render={props => <><SAHeader t = {this.props.i18n}/><ErrorPage {...props}/><Footer/></>} />
                  <Route exact path="/legalnotice/:id" name="Legal Notice"><LegalNotice t = {this.props.i18n} /></Route>

                  <Route exact path="/logout" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/" name="User signature Page" render={props =>  <><SAHeader t = {this.props.i18n}/><Home {...props}/><Footer/></>} />

                  <Route exact path="/agency/:id" name="Agency Form Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/agencies" name="Agency List Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/banner/:id" name="Bandeau Form Page" render={props => <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/banners" name="Bandeau List Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/entity/:id" name="Entity Form Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/entities" name="Entity List Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/function/:id" name="Function Form Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/functions" name="Function List Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/language/:id" name="Language Form Page" render={props => <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/languages" name="Language List Page" render={props => <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/messagesignature" name="Message Signature Form Page" render={props =>  <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/user/:id" name="User Form Page" render={props => <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />
                  <Route exact path="/users" name="User List Page" render={props => <><AdminHeader t = {this.props.i18n}/><AdminContent {...props}/></>} />

                  <Route  name="Page 404" render={props => <><SAHeader t = {this.props.i18n}/><Page404 {...props}/><Footer/></>} />
              </Switch>
            </BlockUi>
            </React.Suspense>
        </HashRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapStateToProps, actionCreators)(withTranslation() (App));
