import { commonService } from '.';
//
function login(email = null, token = null) {
    const body = {
        "UsrEmail": email,
        "IDToken": token
    };
    return commonService.post('Auth', body);
}

//
function loginas(email = null, from = null) {
    const body = {
        "UsrEmail": email,
        "LoginFromEmail": from
    };
    return commonService.post("Auth/loginas", body);
}

//
function backTo() {
    return commonService.post("Auth/backtomyaccount");
}

//
export const userService = {
    login,
    loginas,
    backTo
};
