import { combineReducers } from 'redux';
import { authentication } from './auth.reducer';
import { signatures } from './signature.reducer';
import { loading } from './loader.reducer';
import { alert } from './alert.reducer';
import { entity } from './entity.reducer';
import { user } from './user.reducer';

const RootReducer = combineReducers({
    authentication,
    signatures,
    alert,
    loading,
    entity,
    user
});

export default RootReducer;
