import axios from "axios";
import { TOKEN_USER, TOKEN_USER_ORI } from "../constants";
import { history } from "../tools";
import { loaderActionsType } from '../constants';
import { store } from '../redux/store';

const APIURL = process.env.PUBLIC_URL + "/api";

//
axios.interceptors.request.use((config) => {
    console.log(config)
    let message = "";
    store.dispatch({ type: loaderActionsType.PENDING, message });
    config.headers = getHeaders(config.url);
    return config;
}, error => {
    store.dispatch({ type: loaderActionsType.END });
    return Promise.reject(error);
});
//
axios.interceptors.response.use(response => {
    store.dispatch({ type: loaderActionsType.END });
    return response.data;
}, error => {
    store.dispatch({ type: loaderActionsType.END });
    if (error.response) {
        if (error.response.status === "404") return Promise.reject("SERVER_UNREACHABLE");
        if (error.response.status === "401") {
            localStorage.clear();
            history.push("/login");
        } else
            return Promise.reject(error.response.data.message);
    } else {
        return Promise.reject(error.toString());
    }
});
//
const get = (endpoint, filter = null) => {
    const url = `${APIURL}/` + endpoint;
    return axios.get(url);
}
//
const getWithParam = (endpoint, body) => {
    const url = `${APIURL}/` + endpoint;

    return axios.get(url, { params: body });
}
//
const getWithConfig = (endpoint, data, config) => {
    const url = `${APIURL}/` + endpoint;
    return axios.get(url, data, config);
}
//
const post = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, data);
}
//
const postWithConfig = (endpoint, data, config) => {
    const url = `${APIURL}/` + endpoint;
    return axios.post(url, data, config);
}
//
const put = (endpoint, data) => {
    const url = `${APIURL}/` + endpoint;
    return axios.put(url, data);
}
//
const getHeaders = (url = null) => {
    let token = url.includes("Auth/backtomyaccount")? localStorage.getItem(TOKEN_USER_ORI):localStorage.getItem(TOKEN_USER);
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': token ? 'Bearer ' + token : 'No Auth',
        'headers': 'application/json'
    }
    return headers;
}
//
const deleting = (endpoint) => {
    const url = `${APIURL}/` + endpoint;
    return axios.delete(url);
}
//
export const commonService = {
    get,
    getWithParam,
    post,
    postWithConfig,
    put,
    deleting
};
