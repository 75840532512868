import { signatureActionsType } from '../../constants';

export function signatures (state = {}, action) {

  switch (action.type) {
    case signatureActionsType.LIST_LANGUAGES:
      return {
        ...state,
        languages: action.languages
      };

    case signatureActionsType.LIST_TITLES:
      return {
        ...state,
        titles: action.titles
      };

    case signatureActionsType.LIST_ENTITIES:
      return {
        ...state,
        entities: action.entities
      };

    case signatureActionsType.LIST_CITIES:
      return {
        ...state,
        cities: action.cities
      };

    case signatureActionsType.GET_BOOND_INFO:
      return {
        ...state,
        infosUser: action.infosUser
      };

    case signatureActionsType.GET_SIGNATURE:
      return {
        ...state,
        signature: action.signature
      };

    case signatureActionsType.GET_ENTITY_LOGO:
      return {
        ...state,
        logo: action.logo
      };

    case signatureActionsType.GET_OPTIONS:
      return {
        ...state,
        options: action.options
      };

    case signatureActionsType.GET_BANNERS:
      return {
        ...state,
        banners: action.banners
      };

    case signatureActionsType.GET_BANNER_IMAGE:
      return {
        ...state,
        bannerImage: action.bannerImage
      };

    case signatureActionsType.GET_CDV:
      return {
        ...state,
        cdv: action.cdv
      };
    default:
      return state
  }
}

