import { entityActionsType } from '../../constants';

export function entity (state = null, action) {

    switch (action.type) {
      case entityActionsType.CREATE_ENTITY:
        return {
          ...state,
          entity: action.entity
        };

    case entityActionsType.UPDATE_ENTITY:
        return {
            ...state,
            entity: action.entity
        };

      default:
        return state
    }
  }