import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseMenu } from "../../assets/x.svg";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { Trans } from "react-i18next";
import { history } from "../../tools";
import { authActions } from "../../redux/actions";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { store } from '../../redux/store';
import { authProvider } from '../../services';
import { CURRENT_USER } from '../../constants';
import $ from "jquery";
import AccessibilityIcon  from '@material-ui/icons/Accessibility';
import './../../styles/signastek-admin.scss';



const AdminHeader = (param) => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const [culture, isFrench] = useState(localStorage.getItem('lan') === 'en' || false);
  const user = JSON.parse(localStorage.getItem(CURRENT_USER));
  
  const firstname = user.usrFirstname || "";
  const lastname = user.usrLastname || "";
  const emailLoginFrom = user.usrLoginFrom || "";

  const changeCulture = (x) => {
    isFrench(x);
    const lan = x ? 'en' : 'fr'
    localStorage.setItem('lan', lan);
    param.t.changeLanguage(lan);
    window.location.reload();
    closeMobileMenu();
  }

  const dispatch = useDispatch();

  //Menu active on click
  $(function ($) {
    let url = window.location.href;
    let urlend = url.split("/")[url.split("/").length - 1];
    $('.option a').each(function () {
      if (this.id === urlend) {
        $(this).addClass('active');
      }
      else {
        $(this).removeClass('active');
      }
    });
  });

  const backTo = () => {
    dispatch(authActions.backTo());
  }

  return (
    <div className={click ? "header-admin onfocus" : "header-admin"} >
      <div className="logo-nav-admin">
        <div className="logo-container">
          <a href="/">
            <img src={require("../../assets/saimg/admin/logo_astek_white.png")} className="logo-admin" alt="" />
          </a>
          <ul className="nav-user-admin">
            <li className="pipe"></li>
            <li><span>{firstname} {lastname}</span></li>
            {emailLoginFrom &&
              <li><AccessibilityIcon  onClick={backTo} style={{cursor: 'pointer'}}/></li>
            }
          </ul>
        </div>
      </div>
      <div className="app-title">
        <ul className="nav-user">
          <li className="pipe"></li>
          <li><span>{firstname} {lastname}</span></li>
          {emailLoginFrom &&
            <li><AccessibilityIcon  onClick={backTo} style={{cursor: 'pointer'}}/></li>
          }
        </ul>
        <span className="header-sitename" onClick={() => history.push("/")}><Trans>sitename</Trans></span>
      </div>
      <ul className={click ? "nav-options nav-options-right-admin active" : "nav-options nav-options-right-admin"}>
        {click ? (
          <li className="option ">
            <a className="link" onClick={() => history.push("/")}><Trans>home</Trans></a>
          </li>
        ) : ""}
        {/* <li class="option administrator"><span onClick={() => history.push("/admin")><Trans>admin.menu.adminaccess</Trans></span></li> */}
        <li className="option " onClick={closeMobileMenu}>
          <a id="fr" className={(localStorage.getItem('lan')==='fr'||localStorage.getItem('lan')===null) ? "active-lang-admin" : "lang"} onClick={() => changeCulture(false)}><Trans>fr</Trans></a>
          <b className="lang">&nbsp;|&nbsp;</b>
          <a id="en" className={localStorage.getItem('lan')==='en' ? "active-lang-admin" : "lang"} onClick={() => changeCulture(true)}><Trans>en</Trans></a>
        </li>
        <li className="option popover__wrapper_logout" onClick={closeMobileMenu}>
          <AzureAD provider={authProvider} reduxStore={store}>
            {({ logout, authenticationState }) => {
              const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
              const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
              if (isAuthenticated) {
                return (
                  <React.Fragment>
                    <a onClick={logout} className="link popover__title_logout">
                      {click ? (
                        <b className="mobile-deconnection"><Trans>headerlogoutmobile</Trans></b>
                      ) : (
                        <img width="20" className="logout" src={require("../../assets/img/Icon feather-log-out.svg")} alt="" />
                      )}

                    </a>
                  </React.Fragment>
                );
              } else if(isUnauthenticated) {
                return (
                  <React.Fragment>
                    <a onClick={() => dispatch(authActions.logout())} className="link popover__title_logout">
                      {click ? (
                        <b className="mobile-deconnection"><Trans>headerlogoutmobile</Trans></b>
                      ) : (
                        <img width="20" className="logout" src={require("../../assets/img/Icon feather-log-out.svg")} alt="" />
                      )}

                    </a>
                  </React.Fragment>
                );
              }
            }}
          </AzureAD>
          {/*<a onClick={() => dispatch(authActions.logout())} className="popover__title_logout">
            {click ? (
              <b className="mobile-deconnection"><Trans>headerlogoutmobile</Trans></b>
            ) : (
              <img width="20" src={require("../../assets/img/Icon feather-log-out.svg")} alt="" />
            )}

            </a>*/}
          <div className="popover__content_logout hidden_mobile">
            <p className="popover__message_logout"><Trans>headerlogout</Trans></p>
          </div>
        </li>
      </ul>
      <div className={click ? "mobile-menu opened" : "mobile-menu"} onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon" />
        ) : (
          <MenuIcon className="menu-icon" />
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
