import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';

import App from "./App";
import { I18nextProvider } from 'react-i18next';
import { i18n } from './tools';
import { Scrollbar } from 'react-scrollbars-custom';

import './styles/theme.scss';

const Root = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Scrollbar style={{ width: '100hw', height: '100vh' }}>
        <App />
      </Scrollbar>      
    </I18nextProvider>
  </Provider>
);

ReactDOM.render(<Root/>,document.getElementById('root'));
