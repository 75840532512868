// Sessions variable's name
export const TOKEN_USER = "TOKEN_USER";
export const TOKEN_USER_ORI = "TOKEN_USER_ORI";
export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_LANG = "CURRENT_LANG";
export const APPCONFIG = "APPCONFIG";
export const STRUCTURE = "STR";
export const CONSULTANT = "CLT";
export const VIP = "VIP";

export const authActionsType = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGOUT: 'LOGOUT'
};

export const candidateActionsType = {
    CREATE_CANDIDATE: 'CREATE_CANDIDATE',
    UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
    LIST_CANDIDATES: 'LIST_CANDIDATES',
    LIST_TIMELINE: 'LIST_TIMELINE',
    READ_CV: 'READ_CV'
};

export const alertActionsType = {
    SUCCESS: 'ALERT_SUCCESS',
    WARNING: 'ALERT_WARNING',
    ERROR: 'ALERT_ERROR',
    ERRORBO: 'ALERT_ERRORBO',
    CLEAR: 'ALERT_CLEAR'
};

export const loaderActionsType = {
    PENDING: 'PENDING_LOADER',
    END: 'END_LOADER'
};

export const signatureActionsType = {
    LIST_LANGUAGES: 'LIST_LANGUAGES',
    LIST_TITLES: 'LIST_TITLES',
    LIST_ENTITIES: 'LIST_ENTITIES',
    LIST_CITIES: 'LIST_CITIES',
    GET_BOOND_INFO: 'GET_BOOND_INFO',
    GET_SIGNATURE: 'GET_SIGNATURE',
    GET_ENTITY_LOGO: 'GET_ENTITY_LOGO',
    GET_OPTIONS: 'GET_OPTIONS',
    GET_BANNERS: 'GET_BANNERS',
    GET_BANNER_IMAGE: 'GET_BANNER_IMAGE',
    GET_CDV: 'GET_CDV'
};

export const titleActionsType = {
    LIST_TITLES: 'LIST_TITLES',
    LIST_TITLES_NB_PAGES: 'LIST_TITLES_NB_PAGES',
    GET_TITLE: 'GET_TITLE',
    CREATE_TITLE: 'CREATE_TITLE',
    UPDATE_TITLE: 'UPDATE_TITLE',
    DELETE_TITLE: 'DELETE_TITLE',
    UPLOAD_BANNER: 'UPLOAD_BANNER'
}

export const bannerActionsType = {
    LIST_BANNERS: 'LIST_BANNERS',
    LIST_BANNERS_NB_PAGES: 'LIST_BANNERS_NB_PAGES',
    GET_BANNER: 'GET_BANNER',
    CREATE_BANNER: 'CREATE_BANNER',
    UPDATE_BANNER: 'UPDATE_BANNER',
    DELETE_BANNER: 'DELETE_BANNER'
}

export const userActionsType = {
    LIST_USERS: 'LIST_USERS',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    GET_USER: 'GET_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
}

export const cityActionsType = {
    LIST_CITIES: 'LIST_CITIES',
    GET_CITY: 'GET_CITY',
    CREATE_CITY: 'CREATE_CITY',
    UPDATE_CITY: 'UPDATE_CITY',
    DELETE_CITY: 'DELETE_CITY'
}

export const entityActionsType = {
    LIST_ENTITIES: 'LIST_ENTITIES',
    GET_ENTITY: 'GET_ENTITY',
    CREATE_ENTITY: 'CREATE_ENTITY',
    UPDATE_ENTITY: 'UPDATE_ENTITY',
    DELETE_ENTITY: 'DELETE_ENTITY'
}
