import { bannerService } from '../../services';
import { bannerActionsType } from '../../constants';
import { alertActions } from '../actions';

const getBanners = (body) => {
    return dispatch => {
        return bannerService.getBanners(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banners) {
        return { type: bannerActionsType.LIST_BANNERS, banners }
    }
}
const getBannersPageNumber = (body) => {
    return dispatch => {
        return bannerService.getBannersPageNumber(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(bannersPages) {
        return { type: bannerActionsType.LIST_BANNERS_NB_PAGES, bannersPages }
    }
}
const getBanner = (id) => {
    return dispatch => {
        return bannerService.getBanner(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banner) {
        return { type: bannerActionsType.GET_BANNER, banner }
    }
}
const deleteBanner = (id) => {
    return dispatch => {
        return bannerService.deleteBanner(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banner) {
        return { type: bannerActionsType.DELETE_BANNER, banner }
    }
}
const addBanner = (body, config) => {
    return dispatch => {
        return bannerService.addBanner(body, config)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banner) {
        return { type: bannerActionsType.CREATE_BANNER, banner }
    }
}
const updateBanner = (id, body, config) => {
    return dispatch => {
        return bannerService.updateBanner(id, body, config)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banner) {
        return { type: bannerActionsType.UPDATE_BANNER, banner }
    }
}
const uploadBanner = (body) => {
    return dispatch => {
        return bannerService.uploadBanner(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(banner) {
        return { type: bannerActionsType.UPLOAD_BANNER, banner }
    }
}

export const bannerActions = {
    getBanners,
    getBannersPageNumber,
    getBanner,
    deleteBanner,
    uploadBanner,
    addBanner,
    updateBanner
};