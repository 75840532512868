import React, { useEffect, useState } from "react";
import './../../styles/footer.scss'; 
import { Trans } from "react-i18next";
import CopyrightIcon from '@material-ui/icons/Copyright';
import { Link } from 'react-router-dom';
// import createPalette from "@material-ui/core/styles/createPalette";
// import { useScrollPosition } from "./useScrollPosition";
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';

const Footer = () => {
    const { REACT_APP_DEFAULTLANGUAGE } = process.env;
    let language = (localStorage.getItem('lan') !== "null" && localStorage.getItem('lan') != null)?localStorage.getItem('lan') : REACT_APP_DEFAULTLANGUAGE;
    
    const [csssticky, setCsssticky] = useState("");
    
    useEffect(() => {
        if(language===null || language==='null'){
            language="fr";
        }
    })


    // useScrollPosition(
    //     ({ prevPos, currPos }) => {
    //       const isShow = currPos.y > prevPos.y
    //       if (isShow !== sticky) setSticky(isShow)
    //     },
    //     [sticky]
    // );
    
  return (
    <div className={`footer ${csssticky}`}>
        <div className=" row col-lg-12">
            <div className="footer-div-part col-lg-1 col-0 row"></div>
            <div className="footer-div-part col-lg-8 col-12 row">
                <div className="col-lg-4 col-12">
                    <h6 className="footer-title"><Trans>footertitle1</Trans> </h6>
                    <img className={(language==="fr"?"footer-line footer-line-1-fr":language==="en"?"footer-line footer-line-1-en":"footer-line footer-line-1-fr")} src={require("../../assets/img/FOOTER-LINE.PNG")} alt="" />
                    <div className="footer-details">
                        <p className="footer-p-details"><a href={language==="fr"?"https://astekgroup.fr/":(language==="en"?"https://astekgroup.fr/?lang=en":"https://astekgroup.fr/")} target="_blank" rel="noopener noreferrer">Astekgroup</a></p>
                        <p className="footer-p-details"><a href={language==="fr"?"https://astekjob.com/":(language==="en"?"https://astekjob.com/?lang=en":"https://astekjob.com/")} target="_blank" rel="noopener noreferrer">Astekjob</a></p>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <h6 className="footer-title"><Trans>footertitle2</Trans></h6>
                    <img className={(language==="fr"?"footer-line footer-line-2-fr":language==="en"?"footer-line footer-line-2-en":"footer-line footer-line-2-fr")} src={require("../../assets/img/FOOTER-LINE.PNG")} alt=""  />
                    <p className="footer-p-details-icon">
                        <a href="https://www.instagram.com/astek_group/" target="_blank" rel="noopener noreferrer"><img width="15" src={require("../../assets/img/Instagram.svg")} alt="" /></a>&ensp;
                        <a href="https://twitter.com/astekgroup" target="_blank" rel="noopener noreferrer"><img width="17" src={require("../../assets/img/Twitter.svg")} alt=""  /></a>&ensp; 
                        <a href="https://www.facebook.com/GroupeAstek/" target="_blank" rel="noopener noreferrer"><img width="8.5" src={require("../../assets/img/Facebook.svg")} alt=""  /></a>&ensp;
                        <a href="https://www.linkedin.com/company/astek" target="_blank" rel="noopener noreferrer"><img width="16" src={require("../../assets/img/LinkedIn.svg")} alt=""  /></a>
                    </p>
                </div>
                <div className="col-lg-4 col-12">
                    <h6 className="footer-title"><Trans>footertitle3</Trans></h6>
                    <img className={(language==="fr"?"footer-line footer-line-3-fr":language==="en"?"footer-line footer-line-3-en":"footer-line footer-line-3-fr")} src={require("../../assets/img/FOOTER-LINE.PNG")} alt=""  />
                    <div className="footer-details">
                        <p className="footer-contact-details">77-81ter, rue Marcel Dassault</p>
                        <p className="footer-contact-details">92100 Boulogne-Billancourt</p>
                        <p className="footer-contact-details"><a href="mailto:contact@groupeastek.fr">contact@groupeastek.fr</a></p>
                    </div>
                </div>
            </div>
            <div className="footer-div-part col-lg-3 row">
                <div className="footer-div-part-2 col-lg-12 footer-details footer-legal-mentions" >
                    <p><img width="70" src={require("../../assets/img/ASTEK - PYR.png")} alt=""  /></p>
                    {/* <p><CopyrightIcon style={{fontSize:"small"}}/> 2021 Astek | <Link to={`/legalnotice/${language}`} target="_blank" ><Trans>footerlegalnotice</Trans></Link></p> */}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer;
