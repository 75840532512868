import { commonService } from './';
var appendQuery = require('append-query');
//
const getLanguages = () => {
    return commonService.get('Language');
}

const getTitles = (search) => {
    return commonService.get('Title', {search: search});
}

const getEntities = () => {
    return commonService.get('Entity');
}

const getCities = () => {
    return commonService.get('City');
}

const getBoondInfos = () => {
    return commonService.get('Sign/infos');
}

const generateSign = (body) => {
    return commonService.post('Sign/generate', body);
}

const generateCdv = (body) => {
    return commonService.post('Cdv/generate', body);
}

const getEntityLogo = (name) => {
    return commonService.get(`Entity/${name}`);
}

const getBannerImage = (name) => {
    return commonService.get(`Banner/${name}`);
}

const getOptions = () => {
    return commonService.get('Option');
}

const getBanners = () => {
    return commonService.get('Banner');
}
//
export const signatureService = {
    getLanguages,
    getTitles,
    getEntities,
    getEntityLogo,
    getCities,
    getBoondInfos,
    generateSign,
    generateCdv,
    getOptions,
    getBanners,
    getBannerImage
};
