import { titleService } from '../../services';
import { titleActionsType } from '../../constants';
import { alertActions } from '../actions';

const getTitles = (body) => {
    return dispatch => {
        return titleService.getTitles(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(titles) {
        return { type: titleActionsType.LIST_TITLES, titles }
    }
}
const getTitlesPageNumber = (body) => {
    return dispatch => {
        return titleService.getTitlesPageNumber(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(titlesPages) {
        return { type: titleActionsType.LIST_TITLES_NB_PAGES, titlesPages }
    }
}
const getTitle = (id) => {
    return dispatch => {
        return titleService.getTitle(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(title) {
        return { type: titleActionsType.GET_TITLE, title }
    }
}
const deleteTitle = (id) => {
    return dispatch => {
        return titleService.deleteTitle(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(title) {
        return { type: titleActionsType.DELETE_TITLE, title }
    }
}
const addTitle = (body) => {
    return dispatch => {
        return titleService.addTitle(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(title) {
        return { type: titleActionsType.CREATE_TITLE, title }
    }
}
const updateTitle = (id,body) => {
    return dispatch => {
        return titleService.updateTitle(id,body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(title) {
        return { type: titleActionsType.UPDATE_TITLE, title }
    }
}

export const titleActions = {
    getTitles,
    getTitlesPageNumber,
    getTitle,
    deleteTitle,
    addTitle,
    updateTitle
};


