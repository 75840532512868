import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { TOKEN_USER } from "../constants";

const isExpired = () => {
    var token = localStorage.getItem(TOKEN_USER);
    if(!token) return true;
    try {
        const expiration = jwtDecode(token)?.exp
        return moment.unix(expiration) - moment(Date.now()) < 30
    } catch (error) {
        console.error(error);
        return true;
    }
}

export const jwt = {
    isExpired
}