import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { alertActions } from '../../redux/actions';
import { history } from '../../tools';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
// import createPalette from '@material-ui/core/styles/createPalette';

const useStylesAlert = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
  },
}));

class TopAlert extends Component {
    constructor(props) {
        super(props);
        history.listen((location, action) => {
          this.props.clearAlerts();
        });
    }

    render(){
        const { alert } = this.props;
        const classesAlert = useStylesAlert;

        return (
            <>
              {alert.message &&
                <div style={{ zIndex:'2000', position:'fixed', width: '100%'}} className={classesAlert.root}>
                <Alert severity="error"
                  onClose={() => {this.props.clearAlerts();}}>
                  <AlertTitle><Trans>error.title</Trans></AlertTitle>
                  <Trans>error.info</Trans><Trans>{alert.message}</Trans>
                </Alert>
                </div>
              }
            </>
        );
    }
};

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(withTranslation() (TopAlert));
