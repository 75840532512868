import { userProfileService } from '../../services';
import { userActionsType } from '../../constants';
import { alertActions } from '../actions';

const getUsers = (body) => {
    return dispatch => {
        return userProfileService.getUsers(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(users) {
        return { type: userActionsType.LIST_USERS, users }
    }
}
const updateProfile = (id, body) => {
    return dispatch => {
        return userProfileService.updateProfile(id, body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(profile) {
        return { type: userActionsType.UPDATE_PROFILE, profile }
    }
}

const getUser = (id) => {
    return dispatch => {
        return userProfileService.getUser(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(user) {
        return { type: userActionsType.GET_USER, user }
    }
}
const deleteUser = (id) => {
    return dispatch => {
        return userProfileService.deleteUser(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(user) {
        return { type: userActionsType.DELETE_USER, user }
    }
}
const addUser = (body) => {
    return dispatch => {
        return userProfileService.addUser(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(user) {
        return { type: userActionsType.CREATE_USER, user }
    }
}
const updateUser = (id, body) => {
    return dispatch => {
        return userProfileService.updateUser(id, body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(user) {
        return { type: userActionsType.UPDATE_USER, user }
    }
}


export const userActions = {
    getUsers,
    updateProfile,
    getUser,
    deleteUser,
    addUser,
    updateUser
};


