import { cityService } from '../../services';
import { cityActionsType } from '../../constants';
import { alertActions } from '../actions';

const getCities = (body) => {
    return dispatch => {
        return cityService.getCities(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(cities) {
        return { type: cityActionsType.LIST_CITIES, cities }
    }
}
const getCity = (id) => {
    return dispatch => {
        return cityService.getCity(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(city) {
        return { type: cityActionsType.GET_CITY, city }
    }
}
const deleteCity = (id) => {
    return dispatch => {
        return cityService.deleteCity(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(city) {
        return { type: cityActionsType.DELETE_CITY, city }
    }
}
const addCity = (body) => {
    return dispatch => {
        return cityService.addCity(body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(city) {
        return { type: cityActionsType.CREATE_CITY, city }
    }
}
const updateCity = (id, body) => {
    return dispatch => {
        return cityService.updateCity(id, body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.error(error));
                });
    };
    function success(city) {
        return { type: cityActionsType.UPDATE_CITY, city }
    }
}

export const cityActions = {
    getCities,
    getCity,
    deleteCity,
    addCity,
    updateCity
};


