import { commonService } from '.';
var appendQuery = require('append-query');

const getCities = (body) => {
    return commonService.getWithParam('City/Page', body);
};
const getCity = (id) => {
    return commonService.get(`City/${id}`);
}
const deleteCity = (id) => {
    return commonService.deleting(`City/${id}`);
}
const addCity = (body) => {
    return commonService.post('City', body);
}
const updateCity = (id, body) => {
    return commonService.post('City/' + id, body);
};


export const cityService = {
    getCities,
    getCity,
    deleteCity,
    addCity,
    updateCity
};