import { commonService } from './';
var appendQuery = require('append-query');

const getBanners = (body) => {
    return commonService.post('Banner/Page', body);
}
const getBannersPageNumber = (body) => {
    return commonService.post('Banner/PageNum', body);
}
const getBanner = (id) => {
    return commonService.get(`Banner/Get/${id}`);
}
const deleteBanner = (id) => {
    return commonService.deleting(`Banner/${id}`);
}
const addBanner = (body, config) => {
    return commonService.postWithConfig('Banner', body, config);
}
const uploadBanner = (body) => {
    return commonService.post('Banner/Upload', body);
}
const updateBanner = (id, body, config) => {
    return commonService.postWithConfig(`Banner/${id}`, body, config);
}
export const bannerService = {
    getBanners,
    getBannersPageNumber,
    getBanner,
    deleteBanner,
    addBanner,
    uploadBanner,
    updateBanner
};