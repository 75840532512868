import { commonService } from '.';
var appendQuery = require('append-query');

const getUsers = (body) => {
    return commonService.getWithParam('User', body);
};
const updateProfile = (id,body) => {
    return commonService.post('User/'+id, body);
};
const getUser = (id) => {
    return commonService.get(`User/${id}`);
}
const deleteUser = (id) => {
    return commonService.deleting(`User/${id}`);
}
const addUser = (body) => {
    return commonService.post('User', body);
}
const updateUser = (id, body) => {
    return commonService.post('User/Update/' + id, body);
};

export const userProfileService = {
    getUsers,
    updateProfile,
    getUser,
    deleteUser,
    addUser,
    updateUser
};