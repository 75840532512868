import { commonService } from './';
var appendQuery = require('append-query');

const getTitles = (body) => {
    return commonService.post('Title/Page', body);
}
const getTitlesPageNumber = (body) => {
    return commonService.post('Title/PageNum', body);
}
const getTitle = (id) => {
    return commonService.get(`Title/${id}`);
}
const deleteTitle = (id) => {
    return commonService.deleting(`Title/${id}`);
}
const addTitle = (body) => {
    return commonService.post('Title', body);
}
const updateTitle = (id,body) => {
    return commonService.post(`Title/${id}`, body);
}
export const titleService = {
    getTitles,
    getTitlesPageNumber,
    getTitle,
    deleteTitle,
    addTitle,
    updateTitle
};