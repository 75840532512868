import { commonService } from '.';
var appendQuery = require('append-query');

const getEntities = (body) => {
    return commonService.getWithParam('Entity/Page', body);
};
const getEntity = (id) => {
    return commonService.get(`Entity/${id}`);
}
const deleteEntity = (id) => {
    return commonService.deleting(`Entity/${id}`);
}
const addEntity = (body) => {
    return commonService.post('Entity', body);
}
const updateEntity = (id, body) => {
    return commonService.post('Entity/' + id, body);
};


export const entityService = {
    getEntities,
    getEntity,
    deleteEntity,
    addEntity,
    updateEntity
};