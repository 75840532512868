import { entityService } from '../../services';
import { entityActionsType } from '../../constants';
import { alertActions } from '../actions';

const getEntities = (body) => {
    return dispatch => {
        return entityService.getEntities(body)
            .then(
                response => {
                    return dispatch(success(response));
                })
            .catch(
                error => {
                    return dispatch(alertActions.error(error));
                }
            )
    };
    function success(entities) {
        return { type: entityActionsType.LIST_ENTITIES, entities }
    }
}
const getEntity = (id) => {
    return dispatch => {
        return entityService.getEntity(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(entity) {
        return { type: entityActionsType.GET_ENTITY, entity }
    }
}
const deleteEntity = (id) => {
    return dispatch => {
        return entityService.deleteEntity(id)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(entity) {
        return { type: entityActionsType.DELETE_ENTITY, entity }
    }
}
const addEntity = (body) => {
    return dispatch => {
        return entityService.addEntity(body)
            .then(
                response => {
                    return dispatch(success(response));
                })
            .catch(
                error => {
                    return dispatch(alertActions.errorbo(error));
                }
            );
    };
    function success(entity) {
        return { type: entityActionsType.CREATE_ENTITY, entity }
    }
}
const updateEntity = (id, body) => {
    return dispatch => {
        return entityService.updateEntity(id, body)
            .then(
                response => {
                    return dispatch(success(response));
                },
                error => {
                    return dispatch(alertActions.errorbo(error));
                });
    };
    function success(entity) {
        return { type: entityActionsType.UPDATE_ENTITY, entity }
    }
}

export const entityActions = {
    getEntities,
    getEntity,
    deleteEntity,
    addEntity,
    updateEntity
};


