import { authActionsType, TOKEN_USER, CURRENT_USER, CURRENT_LANG, TOKEN_USER_ORI } from '../../constants';
import { userService } from '../../services';
import { history } from "../../tools";
import { alertActions } from '../actions';

const login = (username = null, token = null) => {
    return dispatch => {
        userService.login(username, token)
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.errorbo(error));
                });
            };

    function success(res) {
        localStorage.setItem(TOKEN_USER, res.data.token);
        localStorage.setItem(TOKEN_USER_ORI, res.data.token);
        localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.user));
        history.push("/");
        return { type: authActionsType.LOGIN_SUCCESS, user: res.data }
    }
    function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const loginas = (email = null, emailFrom = null) => {
    return dispatch => {
        userService.loginas(email, emailFrom)
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.errorbo(error));
                });
            };

    function success(res) {
        localStorage.setItem(TOKEN_USER, res.data.token);
        localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.user));
        history.push("/");
        return { type: authActionsType.LOGIN_SUCCESS, user: res.data }
    }

    function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const backTo = () => {
    return dispatch => {
        userService.backTo()
            .then(
                response => { 
                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.errorbo(error));
                });
            };

    function success(res) {
        localStorage.setItem(TOKEN_USER, res.data.token);
        localStorage.setItem(CURRENT_USER, JSON.stringify(res.data.user));
        history.push("/");
        return { type: authActionsType.LOGIN_SUCCESS, user: res.data }
    }

    function failure(error) { return { type: authActionsType.LOGIN_FAILURE, error } }
}

const logout = () => {
    return dispatch => {
        let lng = localStorage.getItem(CURRENT_LANG);
        localStorage.clear();
        localStorage.setItem(CURRENT_LANG, lng)
        dispatch(signout());
    }
    function signout() { 
        // history.go();
        return { type: authActionsType.LOGOUT } 
    }
}

export const authActions = {
    login,
    loginas,
    backTo,
    logout
};