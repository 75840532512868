import { signatureService } from '../../services';
import { signatureActionsType } from '../../constants';
import { alertActions } from '../actions';
import download from 'downloadjs';

// List of candidates
const getLanguages = () => {
    return dispatch => {
        return signatureService.getLanguages()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(languages) {
        return { type: signatureActionsType.LIST_LANGUAGES, languages } }
}

const getTitles = (search) => {
    return dispatch => {
        return signatureService.getTitles(search)
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(titles) {
        return { type: signatureActionsType.LIST_TITLES, titles } }
}

const getEntities = () => {
    return dispatch => {
        return signatureService.getEntities()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(entities) {
        return { type: signatureActionsType.LIST_ENTITIES, entities } }
}

const getCities = () => {
    return dispatch => {
        return signatureService.getCities()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(cities) {
        return { type: signatureActionsType.LIST_CITIES, cities } }
}
const getBoondInfos = () => {
    return dispatch => {
        return signatureService.getBoondInfos()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(infosUser) {
        return { type: signatureActionsType.GET_BOOND_INFO, infosUser } }
}
const generateSign = (body) => {
    return dispatch => {
        return signatureService.generateSign(body)
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(signature) {
        if(!body.ImageBase64) {
            let file = body.IsImage?"signature.jpg":"signature.htm";
            let mime = body.IsImage?"application/image":"text/html";
            download(signature, file, mime);
        }
        return { type: signatureActionsType.GET_SIGNATURE, signature } }
}

const generateCdv = (body) => {
    return dispatch => {
        return signatureService.generateCdv(body)
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(cdv) {
        return { type: signatureActionsType.GET_CDV, cdv } }
}

const getEntityLogo = (name) => {
    return dispatch => {
        return signatureService.getEntityLogo(name)
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(logo) {
        return { type: signatureActionsType.GET_ENTITY_LOGO, logo } }
}

const getBannerImage = (name) => {
    return dispatch => {
        return signatureService.getBannerImage(name)
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(bannerImage) {
        return { type: signatureActionsType.GET_BANNER_IMAGE, bannerImage } }
}

const getBanners = () => {
    return dispatch => {
        return signatureService.getBanners()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(banners) {
        return { type: signatureActionsType.GET_BANNERS, banners } }
}

const getOptions = () => {
    return dispatch => {
        return signatureService.getOptions()
        .then(
            response => {
                return dispatch(success(response));
            },
            error => {
                return dispatch(alertActions.error(error));
            });
        };
    function success(options) {
        return { type: signatureActionsType.GET_OPTIONS, options } }
}

export const signatureActions = {
    getLanguages,
    getTitles,
    getEntities,
    getEntityLogo,
    getCities,
    getBoondInfos,
    generateSign,
    generateCdv,
    getOptions,
    getBanners,
    getBannerImage
};