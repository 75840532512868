import { userActionsType } from '../../constants';

export function user (state = null, action) {
    switch (action.type) {
        case userActionsType.LIST_USERS:
            return {
                ...state,
                users: action.users
            };
        case userActionsType.CREATE_USER:
            return {
                ...state,
                user: action.user
            };
    
        case userActionsType.UPDATE_USER:
            return {
                ...state,
                user: action.user
            };
        default:
            return state
    }
}